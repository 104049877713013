var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Cascader", {
    attrs: {
      data: _vm.regionArr,
      placeholder: "请选择关区/口岸",
      disabled: _vm.cascaderDisabled,
      clearable: _vm.delVlaue
    },
    on: { "on-change": _vm.onChangeCascader },
    model: {
      value: _vm.cascaderArr,
      callback: function($$v) {
        _vm.cascaderArr = $$v
      },
      expression: "cascaderArr"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }