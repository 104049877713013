var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatePicker", {
    staticStyle: { width: "100%" },
    attrs: {
      value: _vm.value,
      type: "daterange",
      "split-panels": "",
      options: _vm.options,
      editable: false,
      clearable: false,
      placeholder: "请选择时间"
    },
    on: { "on-change": _vm.onChangeDate }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }