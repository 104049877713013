var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "show-header" }, [
    _c(
      "a",
      { on: { click: _vm.back } },
      [
        _c("Icon", {
          attrs: { type: "md-arrow-back", size: "22", color: "#1e9fff" }
        })
      ],
      1
    ),
    _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }